import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { ChevronRightIcon } from '../../icons/ChevronRightIcon';
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon';
import { ChevronUpIcon } from '../../icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { ButtonBase } from '../ButtonBase';
import { MoveButtonProps } from './MoveButton.types';

const mapVariantToClassName = {
  outlined: 'MoveButton-outlined',
  discrete: 'MoveButton-discrete',
  floating: 'MoveButton-floating',
  dark: 'MoveButton-dark',
};

const mapDirectionToIcon = {
  left: ChevronLeftIcon,
  right: ChevronRightIcon,
  up: ChevronUpIcon,
  down: ChevronDownIcon,
};

const MoveButton: React.FC<MoveButtonProps> = React.forwardRef<
  HTMLDivElement,
  MoveButtonProps
>(({ className, style, disabled, variant, direction, onClick }, ref) => {
  const IconComponent = mapDirectionToIcon[direction];

  return (
    <div
      ref={ref}
      data-testid="MoveButton-root"
      className={clsx(
        'MoveButton-root',
        mapVariantToClassName[variant!],
        className,
      )}
      style={style}
      onClick={onClick}
    >
      <ButtonBase
        className="MoveButton-button"
        fullWidth={false}
        disabled={disabled}
      >
        {<IconComponent />}
      </ButtonBase>
    </div>
  );
});

const StyledMoveButton = styled(MoveButton)`
  &.MoveButton-root {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .MoveButton-button {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: auto;

    ${({ theme }) => theme.fns.getShapeStyles('round')};
  }

  &.MoveButton-outlined,
  &.MoveButton-discrete {
    padding: ${({ theme }) => theme.fns.getSpacing(7)};

    .MoveButton-button {
      width: 30px;
      height: 30px;

      .BaseIcon-root {
        font-size: 14px;
      }
    }
  }

  &.MoveButton-dark {
    padding: ${({ theme }) => theme.fns.getSpacing(4)};

    .MoveButton-button {
      width: 48px;
      height: 48px;

      .BaseIcon-root {
        font-size: 30px;
      }
    }
  }

  &.MoveButton-outlined:not(:disabled) .MoveButton-button,
  &.MoveButton-discrete:not(:disabled) .MoveButton-button,
  &.MoveButton-floating:not(:disabled) .MoveButton-button {
    color: var(--common-black);

    background: ${({ theme }) => theme.fns.getColor('common.white', 0.25)};
  }

  &.MoveButton-outlined:not(:disabled) .MoveButton-button,
  &.MoveButton-floating:not(:disabled) .MoveButton-button {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  &.MoveButton-floating:not(:disabled) {
    padding: ${({ theme }) => theme.fns.getSpacing(3)};

    .MoveButton-button {
      width: 42px;
      height: 42px;

      box-shadow: ${({ theme }) => theme.shadows[2]};

      .BaseIcon-root {
        font-size: 20px;
      }
    }
  }

  &.MoveButton-dark:not(:disabled) .MoveButton-button {
    color: var(--common-white);
    background: transparent;

    &:hover {
      background: ${({ theme }) => theme.fns.getColor('common.white', 0.5)};
    }
  }

  &.MoveButton-outlined:not(:disabled) .MoveButton-button:hover,
  &.MoveButton-discrete:not(:disabled) .MoveButton-button:hover,
  &.MoveButton-floating:not(:disabled) .MoveButton-button:hover {
    color: var(--common-black);

    background: ${({ theme }) => theme.fns.getColor('common.white', 0.25)};

    &:hover {
      background: var(--common-white);
    }
  }
`;

StyledMoveButton.displayName = 'MoveButton';

StyledMoveButton.defaultProps = {
  disabled: false,
  variant: 'outlined',
};

export { StyledMoveButton as MoveButton };
