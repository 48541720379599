import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';

import { useControlled } from '../../../hooks/useControlled';
import { InputProps } from './Input.types';

const Input: React.FC<InputProps> = ({
  id,
  name,
  className,
  style,
  autoComplete,
  autoFocus,
  disabled,
  defaultValue,
  fullWidth,
  maxLength,
  multiline,
  placeholder,
  required,
  rows,
  minRows,
  maxRows,
  type,
  value: valueProp,
  onChange,
}) => {
  const [value, setValue] = useControlled<NonNullable<typeof valueProp>>({
    controlled: valueProp,
    default: defaultValue,
  });
  const [count, setCount] = React.useState(
    `${value ?? defaultValue ?? ''}`.length,
  );

  const handleChange = React.useCallback<NonNullable<typeof onChange>>(
    (event) => {
      if (maxLength !== undefined) {
        setCount(event.target.value.length);
      }

      setValue(event.target.value);

      if (onChange) {
        onChange(event);
      }
    },
    [maxLength, onChange, setValue],
  );

  const commonProps = {
    id,
    name,
    autoComplete,
    autoFocus,
    disabled,
    placeholder,
    required,
    value,
    maxLength,
    onChange: handleChange,
  };

  if (multiline) {
    /**
     * Neither rows, minRows or maxRows can't be less than 1.
     * When rows is present it is used as minRows.
     * minRows can't never be less than the final minRows (whether it comes from
     * rows or the actual minRows prop).
     */
    const cappedRows = rows && Math.max(1, rows);
    const cappedMinRows = cappedRows ?? (minRows && Math.max(1, minRows));
    const cappedMaxRows = maxRows && Math.max(cappedMinRows ?? 1, maxRows);

    return (
      <div
        data-testid="Input-root"
        className={clsx(
          'Input-root',
          'Input-textArea',
          fullWidth && 'Moonsift-fullWidth',
          className,
        )}
        style={style}
      >
        <TextareaAutosize
          className={clsx(
            'Input-input',
            'Input-textArea',
            fullWidth && 'Moonsift-fullWidth',
            className,
          )}
          rows={cappedRows}
          minRows={cappedMinRows}
          maxRows={cappedMaxRows}
          {...commonProps}
        />
        {maxLength !== undefined && (
          <p className="Input-count">{`${count}/${maxLength}`}</p>
        )}
      </div>
    );
  }

  return (
    <div
      data-testid="Input-root"
      className={clsx(
        'Input-root',
        fullWidth && 'Moonsift-fullWidth',
        className,
      )}
      style={style}
    >
      <input
        className={clsx(
          'Input-input',
          fullWidth && 'Moonsift-fullWidth',
          className,
        )}
        type={type}
        {...commonProps}
      />
      {maxLength !== undefined && (
        <p className="Input-count">{`${count}/${maxLength}`}</p>
      )}
    </div>
  );
};

const StyledInput = styled(Input)`
  &.Input-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
  }

  .Input-input {
    outline: 0px;
    padding: ${({ theme }) => theme.fns.getSpacing(7, 10)};
    border: ${({ theme }) => theme.fns.getBorder('secondary')};

    ${({ theme }) => theme.fns.getShapeStyles('main')}
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r16')}

    &::placeholder {
      color: var(--grey-2);
    }

    &:focus::placeholder {
      color: var(--grey-3);
    }

    &:hover,
    &:focus {
      border: ${({ theme }) => theme.fns.getBorder('primary')};
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      cursor: not-allowed;
      border-color: var(--grey-5);
      color: var(--grey-2);
    }
  }

  .Input-textArea {
    resize: none;
  }

  .Input-count {
    margin: 0;
    text-align: right;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}
  }
`;

StyledInput.displayName = 'Input';

StyledInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  type: 'text',
  multiline: false,
};

export { StyledInput as Input };
