import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { Input } from '../Input';
import { FormHelperText } from '../FormHelperText';
import { TextFieldProps } from './TextField.types';

const TextField: React.FC<TextFieldProps> = ({
  id,
  name,
  className,
  style,
  autoComplete,
  autoFocus,
  disabled,
  defaultValue,
  error,
  fullWidth,
  helperText,
  maxLength,
  multiline,
  placeholder,
  required,
  rows,
  minRows,
  maxRows,
  type,
  value,
  onChange,
}) => {
  return (
    <div
      data-testid="TextField-root"
      className={clsx(
        'TextField-root',
        fullWidth && 'Moonsift-fullWidth',
        className,
      )}
      style={style}
    >
      <Input
        id={id}
        name={name}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline={multiline}
        placeholder={placeholder}
        required={required}
        rows={rows}
        maxLength={maxLength}
        minRows={minRows}
        maxRows={maxRows}
        type={type}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      />
      {helperText && (
        <FormHelperText className="TextField-helperText" error={error}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

const StyledTextField = styled(TextField)`
  &.TextField-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
  }

  .TextField-helperText {
    margin: ${({ theme }) => theme.fns.getSpacing(4, 0, 0, 10)};
  }
`;

StyledTextField.displayName = 'TextField';

StyledTextField.defaultProps = {
  autoFocus: false,
  disabled: false,
  type: 'text',
};

export { StyledTextField as TextField };
