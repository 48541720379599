import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { NotificationsIconProps } from './NotificationsIcon.types';

const NotificationsIcon: React.FC<NotificationsIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="NotificationsIcon-root"
    className={clsx('NotificationsIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.06855 7.27258V11.818C4.05109 12.224 3.77628 12.2723 3.15945 12.2726C1.43104 12.4448 1.31196 14.5969 3.15945 14.9999H16.7958C18.4267 14.4735 18.524 12.4781 16.7958 12.2726C16.1469 12.2757 15.876 12.2198 15.8867 11.818V7.27258C15.3051 -0.134933 4.52291 -0.258767 4.06855 7.27258Z"
        strokeWidth="1.35"
        stroke="currentColor"
      />
      <path
        d="M7.70508 16.8181C7.70508 19.5454 12.2505 19.5454 12.2505 16.8181"
        strokeWidth="1.35"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

NotificationsIcon.defaultProps = BaseIcon.defaultProps;

export { NotificationsIcon };
