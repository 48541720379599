import * as React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { Typography } from '../../core/Typography';
import { CheckboxProps } from '../Checkbox';
import { FormControlLabelProps } from './FormControlLabel.types';

const mapLabelPlacementToClassName = {
  start: 'FormControlLabel-labelPlacementStart',
  end: 'FormControlLabel-labelPlacementEnd',
};

const FormControlLabel = React.forwardRef<
  HTMLLabelElement,
  FormControlLabelProps
>(function FormControlLabel(props, ref) {
  const { className, style, control, label, labelPlacement } = props;

  // Override Checkbox props if the same props are provided to FormControlLabel
  const checkboxKeys: Array<keyof CheckboxProps> = [
    'id',
    'name',
    'defaultChecked',
    'checked',
    'disabled',
    'required',
    'onChange',
  ];
  const controlProps = checkboxKeys.reduce<CheckboxProps>((acc, key) => {
    if (props[key] !== undefined) {
      return {
        ...acc,
        [key]: props[key],
      };
    }

    return acc;
  }, {});

  return (
    <label
      data-testid="FormControlLabel-root"
      htmlFor={controlProps.id}
      ref={ref}
      className={clsx(
        'FormControlLabel-root',
        labelPlacement && mapLabelPlacementToClassName[labelPlacement],
        controlProps.disabled && 'FormControlLabel-disabled',
        className,
      )}
      style={style}
    >
      {React.cloneElement(control, controlProps)}
      <Typography
        className={clsx('FormControlLabel-label')}
        variant="primary.r14"
        component="span"
      >
        {label}
      </Typography>
    </label>
  );
});

const StyledFormControlLabel = styled(FormControlLabel)`
  &.FormControlLabel-root {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
  }

  &.FormControlLabel-labelPlacementStart {
    flex-direction: row-reverse;
  }

  &.FormControlLabel-labelPlacementEnd {
    flex-direction: row;
  }

  &.FormControlLabel-disabled {
    cursor: not-allowed;
    color: var(--grey-4);
  }
`;

StyledFormControlLabel.defaultProps = {
  labelPlacement: 'end',
};

export { StyledFormControlLabel as FormControlLabel };
