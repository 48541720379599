import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CommentIconProps } from './CommentIcon.types';

const CommentIcon: React.FC<CommentIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="CommentIcon-root"
    className={clsx('CommentIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 9.52779C18.5032 10.7743 18.212 12.004 17.65 13.1167C16.9836 14.45 15.9592 15.5714 14.6915 16.3554C13.4237 17.1394 11.9628 17.555 10.4722 17.5556C9.22567 17.5588 7.99599 17.2676 6.88332 16.7056L1.5 18.5L3.29444 13.1167C2.73243 12.004 2.44119 10.7743 2.44444 9.52779C2.44502 8.03723 2.86058 6.57627 3.64456 5.30854C4.42855 4.04081 5.55001 3.01639 6.88332 2.35003C7.99599 1.78802 9.22567 1.49678 10.4722 1.50003H10.9444C12.913 1.60863 14.7723 2.43952 16.1664 3.83361C17.5605 5.2277 18.3914 7.08702 18.5 9.05557V9.52779Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

CommentIcon.defaultProps = BaseIcon.defaultProps;

export { CommentIcon };
