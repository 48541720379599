import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ProTickTwoToneIconProps } from './ProTickTwoToneIcon.types';

const ProTickTwoToneIcon: React.FC<ProTickTwoToneIconProps> = ({
  className,
  style,
  size,
  color,
  secondaryColor,
}) => (
  <BaseIcon
    data-testid="ProTickTwoToneIcon-root"
    className={clsx('ProTickTwoToneIcon-root', className)}
    style={style}
    size={size}
    color={color}
    secondaryColor={secondaryColor}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.3047 0.67263C9.69238 0.297596 10.3076 0.297595 10.6953 0.67263L12.1208 2.05167C12.3425 2.26613 12.6507 2.36629 12.9561 2.32309L14.92 2.04531C15.4541 1.96977 15.9518 2.33141 16.045 2.86268L16.3877 4.81624C16.441 5.12005 16.6315 5.38226 16.9039 5.52683L18.656 6.45642C19.1325 6.70922 19.3226 7.29436 19.0857 7.77895L18.2147 9.56083C18.0792 9.83794 18.0792 10.1621 18.2147 10.4392L19.0857 12.2211C19.3226 12.7056 19.1325 13.2908 18.656 13.5436L16.9039 14.4732C16.6315 14.6177 16.441 14.88 16.3877 15.1838L16.045 17.1373C15.9518 17.6686 15.4541 18.0302 14.92 17.9547L12.9561 17.6769C12.6507 17.6337 12.3425 17.7339 12.1208 17.9483L10.6953 19.3274C10.3076 19.7024 9.69238 19.7024 9.3047 19.3274L7.8792 17.9483C7.65751 17.7339 7.34926 17.6337 7.04385 17.6769L5.08002 17.9547C4.54594 18.0302 4.0482 17.6686 3.955 17.1373L3.61232 15.1838C3.55903 14.88 3.36852 14.6177 3.09605 14.4732L1.344 13.5436C0.867527 13.2908 0.677404 12.7056 0.914286 12.2211L1.78532 10.4392C1.92078 10.1621 1.92078 9.83794 1.78532 9.56083L0.914286 7.77895C0.677405 7.29436 0.867526 6.70922 1.344 6.45642L3.09605 5.52683C3.36852 5.38226 3.55903 5.12005 3.61232 4.81624L3.955 2.86268C4.0482 2.33141 4.54594 1.96977 5.08001 2.04531L7.04385 2.32309C7.34926 2.36629 7.65751 2.26613 7.8792 2.05167L9.3047 0.67263Z"
        fill="currentColor"
      />
      <path
        d="M13.75 7L8.5 13L6.25 10.75"
        stroke="var(--secondary-color)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </BaseIcon>
);

ProTickTwoToneIcon.defaultProps = BaseIcon.defaultProps;

export { ProTickTwoToneIcon };
