import React from 'react';
import styled, { keyframes } from 'styled-components';
import clsx from 'clsx';

import { LoadingDotsRef, LoadingDotsProps } from './LoadingDots.types';

const lightLeftDotKeyframe = keyframes`
  0% { background-color: var(--secondary-main); }
  33% { background-color: var(--grey-5); }
  66% { background-color: var(--grey-5); }
  100% { background-color: var(--secondary-main); }
`;

const lightCenterDotKeyframe = keyframes`
  0% { background-color: var(--grey-5); }
  33% { background-color: var(--secondary-main); }
  66% { background-color: var(--grey-5); }
  100% { background-color: var(--grey-5); }
`;

const lightRightDotKeyframe = keyframes`
  0% { background-color: var(--grey-5); }
  33% { background-color: var(--grey-5); }
  66% { background-color: var(--secondary-main); }
  100% { background-color: var(--grey-5); }
`;

const darkLeftDotKeyframe = keyframes`
  0% { background-color: var(--common-white); }
  33% { background-color: var(--grey-3); }
  66% { background-color: var(--grey-3); }
  100% { background-color: var(--common-white); }
`;

const darkCenterDotKeyframe = keyframes`
  0% { background-color: var(--grey-3); }
  33% { background-color: var(--common-white); }
  66% { background-color: var(--grey-3); }
  100% { background-color: var(--grey-3); }
`;

const darkRightDotKeyframe = keyframes`
  0% { background-color: var(--grey-3); }
  33% { background-color: var(--grey-3); }
  66% { background-color: var(--common-white); }
  100% { background-color: var(--grey-3); }
`;

const variantDarkClassName = 'LoadingDots-variantDark';
const variantLightClassName = 'LoadingDots-variantLight';

const LoadingDotsRoot = styled.div`
  font-size: 0px;
  line-height: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const LoadingDotsDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 3px;
  font-size: 0px;
  line-height: 0px;
  display: flex;
  flex-direction: row;
  margin: 0px 2px;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  .${variantDarkClassName} &:nth-child(1) {
    animation-name: ${darkLeftDotKeyframe};
  }

  .${variantDarkClassName} &:nth-child(2) {
    animation-name: ${darkCenterDotKeyframe};
  }

  .${variantDarkClassName} &:nth-child(3) {
    animation-name: ${darkRightDotKeyframe};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${variantLightClassName} &:nth-child(1) {
    animation-name: ${lightLeftDotKeyframe};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${variantLightClassName} &:nth-child(2) {
    animation-name: ${lightCenterDotKeyframe};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${variantLightClassName} &:nth-child(3) {
    animation-name: ${lightRightDotKeyframe};
  }
`;

export const loadingDotsClassNames = {
  root: LoadingDotsRoot.toString().slice(1),
  variantDark: variantDarkClassName,
  variantLight: variantLightClassName,
  dot: LoadingDotsDot.toString().slice(1),
};

const mapVariantToClassName = {
  dark: loadingDotsClassNames.variantDark,
  light: loadingDotsClassNames.variantLight,
};

export const LoadingDots = React.forwardRef<LoadingDotsRef, LoadingDotsProps>(
  ({ className, style, variant = 'light' }, ref) => {
    return (
      <LoadingDotsRoot
        data-testid="LoadingDots"
        ref={ref}
        className={clsx(mapVariantToClassName[variant], className)}
        style={style}
      >
        <LoadingDotsDot />
        <LoadingDotsDot />
        <LoadingDotsDot />
      </LoadingDotsRoot>
    );
  },
);
