import { createGlobalStyle } from 'styled-components';

import { StylingFunction } from '../../../types/common';
import { CssBaselineProps } from './CssBaseline.types';

// Heavily based on Materil UI's own CssBaseline

const setColorCssVariables: StylingFunction<CssBaselineProps> = ({ theme }) => {
  return Object.entries(theme.colors).reduce((allCss, [group, itemMap]) => {
    const groupCss = Object.entries(itemMap!).reduce(
      (groupCss, [name, value]) => {
        const cssVariableName = `--${group}-${name}`;
        return `
          ${groupCss}
          ${cssVariableName}: ${value};
        `;
      },
      ``,
    );

    return `
      ${allCss}
      ${groupCss}
    `;
  }, '');
};

const CssBaseline = createGlobalStyle<CssBaselineProps>`
  /* stylelint-disable-next-line selector-type-no-unknown */
  ${({ theme, normalize }) => (normalize ? theme.fns.normalize() : '')}

  :root {
    ${setColorCssVariables}
  }

  html {
    /* Antialiasing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Change from so that width and height is not affected by padding or border. */
    box-sizing: border-box;

    /* stylelint-disable-next-line property-no-vendor-prefix -- Fix font resize problem in  iOS */
    -webkit-text-size-adjust: 100%;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  strong, b {
    font-weight: 700;
  }

  body {
    margin: 0;
    color: ${({ theme }) => theme.fns.getColor('common.black')};
    background-color: ${({ theme }) => theme.fns.getColor('common.white')};

    /* FIXME: is it going to be primary.l16 for all themes? if not, we should
    find a way of make this theme-independent. */
    ${({ theme }) => theme.fns.getTypographyStyles('primary.l16')}
  }

  /* Set the vertical rhythm for block elements (following Typography.js) */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dl,
  dd,
  p,
  figure,
  pre,
  table,
  fieldset,
  blockquote,
  form,
  noscript,
  iframe,
  img,
  hr,
  address {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1rem;
  }

  /* Common CSS utility classes */

  .Moonsift-fullWidth {
    width: 100%;
  }

  .Moonsift-error {
    color: var(--red-main);
  }
`;

CssBaseline.defaultProps = {
  normalize: false,
};

export { CssBaseline };
