import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ChevronDownThinIconProps } from './ChevronDownThinIcon.types';

const ChevronDownThinIcon: React.FC<ChevronDownThinIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="ChevronDownThinIcon-root"
    className={clsx('ChevronDownThinIcon-root', className)}
    style={style}
    size={size}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6L10 15L19 6"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </BaseIcon>
);

ChevronDownThinIcon.defaultProps = BaseIcon.defaultProps;

export { ChevronDownThinIcon };
