import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CheckIconProps } from './CheckIcon.types';

const CheckIcon: React.FC<CheckIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="CheckIcon-root"
    className={clsx('CheckIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 5L7.5 15L3.75 11.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

CheckIcon.defaultProps = BaseIcon.defaultProps;

export { CheckIcon };
