import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { AddIconProps } from './AddIcon.types';

const AddIcon: React.FC<AddIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="AddIcon-root"
    className={clsx('AddIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1.83447V18.1678"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M1.8335 9.99951H18.1668"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

AddIcon.defaultProps = BaseIcon.defaultProps;

export { AddIcon };
