import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { PersonAddIconProps } from './PersonAddIcon.types';

const PersonAddIcon: React.FC<PersonAddIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="PersonAddIcon-root"
    className={clsx('PersonAddIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7987 5.43058C12.6388 7.5876 11.0036 9.34724 9.20847 9.34724C7.41333 9.34724 5.77527 7.58801 5.6182 5.43058C5.455 3.18666 7.04615 1.51392 9.20847 1.51392C11.3708 1.51392 12.9619 3.22746 12.7987 5.43058Z"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M9.20828 11.9583C5.65881 11.9583 2.0563 13.9166 1.38965 17.6129C1.30805 18.0585 1.56141 18.486 2.02814 18.486H16.3888C16.8556 18.486 17.1077 18.0585 17.0273 17.6129C16.3603 13.9166 12.7578 11.9583 9.20828 11.9583Z"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        stroke="currentColor"
      />
      <path
        d="M16.7156 6.73608V11.3055"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M19.0001 9.02075H14.4307"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

PersonAddIcon.defaultProps = BaseIcon.defaultProps;

export { PersonAddIcon };
