import * as React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { useControlled } from '../../../hooks/useControlled';
import { AccordionProps } from './Accordion.types';
import { AccordionContext } from './Accordion.context';
import { expandedClassName } from './Accordion.classNames';

export const AccordionRoot = styled.div`
  border-bottom: ${({ theme }) => theme.fns.getBorder('secondary')};
`;

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      className,
      style,
      defaultExpanded = false,
      expanded: expandedProp,
      onChange,
      children: childrenProp,
    },
    ref,
  ) => {
    const [expanded, setExpanded] = useControlled({
      controlled: expandedProp,
      default: defaultExpanded,
    });

    const handleChange = React.useCallback(
      (event) => {
        setExpanded(!expanded);

        if (onChange) {
          onChange(event, !expanded);
        }
      },
      [expanded, onChange, setExpanded],
    );

    const [summary, ...children] = React.Children.toArray(
      childrenProp,
    ) as React.ReactElement[];

    const contextValue = React.useMemo(
      () => ({ expanded, toggle: handleChange }),
      [expanded, handleChange],
    );

    return (
      <AccordionRoot
        data-testid="Accordion"
        ref={ref}
        className={clsx(className, expanded && expandedClassName)}
        style={style}
      >
        <AccordionContext.Provider value={contextValue}>
          {summary}
        </AccordionContext.Provider>
        <div
          aria-labelledby={summary.props.id}
          id={summary.props['aria-controls']}
          role="region"
        >
          {children}
        </div>
      </AccordionRoot>
    );
  },
);
