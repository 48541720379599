import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MenuIconProps } from './MenuIcon.types';

const MenuIcon: React.FC<MenuIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="MenuIcon-root"
    className={clsx('MenuIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="0.75"
        y1="-0.75"
        x2="15.9167"
        y2="-0.75"
        transform="matrix(1 0 0 -1 1.66675 3.33334)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <line
        x1="2.41675"
        y1="10.0833"
        x2="17.5834"
        y2="10.0833"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <line
        x1="2.41675"
        y1="15.9167"
        x2="17.5834"
        y2="15.9167"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

MenuIcon.defaultProps = BaseIcon.defaultProps;

export { MenuIcon };
