import React from 'react';

import { UseEnhancedEffectHook } from './useEnhancedEffect.types';

/**
 * We want to use `useLayoutEffect` when we are in SSR process and
 * `useEffect` otherwise.
 */
export const useEnhancedEffect: UseEnhancedEffectHook =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;
