import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MoveUpIconProps } from './MoveUpIcon.types';

const MoveUpIcon: React.FC<MoveUpIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="MoveUpIcon-root"
    className={clsx('MoveUpIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 10.4312L10 1L17.5 10.4312H14V19H6V10.4312H2.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

MoveUpIcon.defaultProps = BaseIcon.defaultProps;

export { MoveUpIcon };
