import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { DatedIconProps } from './DatedIcon.types';

const DatedIcon: React.FC<DatedIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="DatedIcon-root"
    className={clsx('DatedIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6384 5.9389V10.1039L14.2084 12.9598"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M0.600098 10.1562H3.73842H6.67753L3.61756 14.2689L0.600098 10.1562Z"
        fill="currentColor"
      />
      <path
        d="M3.73842 10.1562C3.73718 10.0992 3.73656 10.0421 3.73656 9.98491C3.73656 5.71304 7.1996 2.25 11.4715 2.25C15.7433 2.25 19.2064 5.71304 19.2064 9.98491C19.2064 14.2568 15.7433 17.7198 11.4715 17.7198C9.49324 17.7198 7.68845 16.9772 6.3207 15.7555M3.73842 10.1562H0.600098L3.61756 14.2689L6.67753 10.1562H3.73842Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

DatedIcon.defaultProps = BaseIcon.defaultProps;

export { DatedIcon };
