import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { BookmarkTwoToneIconProps } from './BookmarkTwoToneIcon.types';

const BookmarkTwoToneIcon: React.FC<BookmarkTwoToneIconProps> = ({
  className,
  style,
  size,
  color,
  secondaryColor,
}) => (
  <BaseIcon
    data-testid="BookmarkTwoToneIcon-root"
    className={clsx('BookmarkTwoToneIcon-root', className)}
    style={style}
    size={size}
    color={color}
    secondaryColor={secondaryColor}
  >
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6001 21.5999V2.3999H20.4001V21.5999L12.0001 13.1603L3.6001 21.5999Z"
        fill="var(--secondary-color)"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </BaseIcon>
);

BookmarkTwoToneIcon.defaultProps = BaseIcon.defaultProps;

export { BookmarkTwoToneIcon };
