import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ShareIconProps } from './ShareIcon.types';

const ShareIcon: React.FC<ShareIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="ShareIcon-root"
    className={clsx('ShareIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6.6001C16.3807 6.6001 17.5 5.48081 17.5 4.1001C17.5 2.71939 16.3807 1.6001 15 1.6001C13.6193 1.6001 12.5 2.71939 12.5 4.1001C12.5 5.48081 13.6193 6.6001 15 6.6001Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M5 12.4338C6.38071 12.4338 7.5 11.3145 7.5 9.93384C7.5 8.55313 6.38071 7.43384 5 7.43384C3.61929 7.43384 2.5 8.55313 2.5 9.93384C2.5 11.3145 3.61929 12.4338 5 12.4338Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M15 18.2673C16.3807 18.2673 17.5 17.148 17.5 15.7673C17.5 14.3866 16.3807 13.2673 15 13.2673C13.6193 13.2673 12.5 14.3866 12.5 15.7673C12.5 17.148 13.6193 18.2673 15 18.2673Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M7.15845 11.1919L12.8501 14.5086"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M12.8418 5.3584L7.15845 8.67506"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

ShareIcon.defaultProps = BaseIcon.defaultProps;

export { ShareIcon };
