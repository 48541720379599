import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MediumIconProps } from './MediumIcon.types';

const MediumIcon: React.FC<MediumIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="MediumIcon-root"
    className={clsx('MediumIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V20H20V0H0ZM16.615 4.73833L15.5425 5.76667C15.497 5.80129 15.4619 5.84771 15.4408 5.90088C15.4198 5.95406 15.4138 6.01196 15.4233 6.06833V13.6242C15.4139 13.6804 15.4201 13.7381 15.4411 13.7912C15.4621 13.8442 15.4971 13.8904 15.5425 13.925L16.59 14.9533V15.1792H11.3217V14.9542L12.4067 13.9C12.5133 13.7933 12.5133 13.7625 12.5133 13.6V7.49167L9.49667 15.1542H9.08833L5.575 7.49167V12.6275C5.56055 12.734 5.57052 12.8424 5.60416 12.9445C5.6378 13.0465 5.69423 13.1396 5.76917 13.2167L7.18083 14.9283V15.1542H3.17917V14.9292L4.59167 13.2167C4.666 13.1396 4.72117 13.0461 4.75272 12.9437C4.78428 12.8414 4.79134 12.7331 4.77333 12.6275V6.68917C4.78165 6.60782 4.77067 6.52566 4.74128 6.44935C4.71188 6.37304 4.66491 6.30475 4.60417 6.25L3.34917 4.73833V4.51333H7.24417L10.255 11.1158L12.9017 4.5125H16.615V4.73833Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

MediumIcon.defaultProps = BaseIcon.defaultProps;

export { MediumIcon };
