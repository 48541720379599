import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CheckboxUncheckedIconProps } from './CheckboxUncheckedIcon.types';

const CheckboxUncheckedIcon: React.FC<CheckboxUncheckedIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="CheckboxUncheckedIcon-root"
    className={clsx('CheckboxUncheckedIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2548 2.74517L17.608 2.39201L17.2548 2.74517C17.4111 2.90144 17.4992 3.11315 17.5 3.33413V16.6659C17.4992 16.8868 17.4111 17.0986 17.2548 17.2548C17.0986 17.4111 16.8868 17.4992 16.6659 17.5H3.33413C3.11315 17.4992 2.90144 17.4111 2.74517 17.2548L2.39162 17.6084L2.74517 17.2548C2.58891 17.0986 2.50078 16.8869 2.5 16.6659V3.33411C2.50078 3.11314 2.58891 2.90143 2.74517 2.74517C2.90143 2.58891 3.11314 2.50078 3.33411 2.5H16.6659C16.8869 2.50078 17.0986 2.58891 17.2548 2.74517Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

CheckboxUncheckedIcon.defaultProps = BaseIcon.defaultProps;

export { CheckboxUncheckedIcon };
