import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { StandardButtonRef, StandardButtonProps } from './StandardButton.types';

const variantPrimaryBlackClassName = 'StandardButton-variantPrimaryBlack';
const variantPrimaryWhiteClassName = 'StandardButton-variantPrimaryWhite';
const variantSecondaryClassName = 'StandardButton-variantSecondary';

const StandardButtonRoot = styled(ButtonBase)`
  ${({ theme }) => theme.fns.getTypographyStyles('primary.b14')}
  ${({ theme }) => theme.fns.getShapeStyles('main')}

  &.${buttonBaseClassNames.withIcons}:not(.${buttonBaseClassNames.fullWidth}) {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
  }

  &.${variantPrimaryBlackClassName}:not(:disabled),
  &.${variantPrimaryWhiteClassName}:not(:disabled) {
    border: ${({ theme }) => theme.fns.getBorder('primary')};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${variantSecondaryClassName}:not(:disabled) {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};

    &:hover {
      background: ${({ theme }) => theme.fns.getColor('grey.6')};
    }
  }
`;

export const standardButtonClassNames = {
  root: StandardButtonRoot.toString().slice(1),
  variantPrimaryBlack: variantPrimaryBlackClassName,
  variantPrimaryWhite: variantPrimaryWhiteClassName,
  variantSecondary: variantSecondaryClassName,
};

const mapVariantToClassName = {
  'primary-black': standardButtonClassNames.variantPrimaryBlack,
  'primary-white': standardButtonClassNames.variantPrimaryWhite,
  'secondary': standardButtonClassNames.variantSecondary,
};

export const StandardButton = React.forwardRef<
  StandardButtonRef,
  StandardButtonProps
>(
  (
    {
      className,
      style,
      type = 'button',
      variant = 'primary-black',
      fullWidth = false,
      disabled = false,
      loading = false,
      onClick,
      startIcon,
      endIcon,
      children,
    },
    ref,
  ) => {
    return (
      <StandardButtonRoot
        ref={ref}
        className={clsx(variant && mapVariantToClassName[variant], className)}
        style={style}
        color={variant === 'primary-black' ? 'common.black' : 'common.white'}
        fullWidth={fullWidth}
        disabled={disabled}
        loading={loading}
        startIcon={startIcon}
        endIcon={endIcon}
        type={type}
        onClick={onClick}
      >
        {children}
      </StandardButtonRoot>
    );
  },
);
