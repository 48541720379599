import * as React from 'react';
import styled from 'styled-components';

import { TypographyProps } from './Typography.types';

export const TypographyRoot = styled.span<
  Pick<TypographyProps, 'color' | 'variant' | 'align'>
>`
  color: ${({ theme, color }) => theme.fns.getColor(color)};
  text-align: ${({ align }) => align};

  ${({ theme, variant }) => {
    if (variant) {
      return theme.fns.getTypographyStyles(variant);
    }
    return '';
  }}
`;

export const typographyClassNames = {
  root: TypographyRoot.toString().slice(1),
};

export const Typography = React.forwardRef<HTMLSpanElement, TypographyProps>(
  (
    {
      className,
      style,
      component,
      children,
      color = 'inherit',
      variant,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <TypographyRoot
        data-testid="Typography"
        ref={ref}
        as={component}
        className={className}
        style={style}
        color={color}
        variant={variant}
        {...otherProps}
      >
        {children}
      </TypographyRoot>
    );
  },
);
