import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { AddCircleIconProps } from './AddCircleIcon.types';

const AddCircleIcon: React.FC<AddCircleIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    date-testid="AddCircleIcon-root"
    className={clsx('AddCircleIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
      <path
        d="M10 5.91724V14.0839"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M5.91675 9.99976H14.0834"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

AddCircleIcon.defaultProps = BaseIcon.defaultProps;

export { AddCircleIcon };
