import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MoreTwoToneIconProps } from './MoreTwoToneIcon.types';

const MoreTwoToneIcon: React.FC<MoreTwoToneIconProps> = ({
  className,
  style,
  size,
  color,
  secondaryColor,
}) => (
  <BaseIcon
    data-testid="MoreTwoToneIcon-root"
    className={clsx('MoreTwoToneIcon-root', className)}
    style={style}
    size={size}
    color={color}
    secondaryColor={secondaryColor}
  >
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        fill="var(--secondary-color)"
      />
      <circle r="1" cx="5" cy="10" fill="currentColor" />
      <circle r="1" cx="10" cy="10" fill="currentColor" />
      <circle r="1" cx="15" cy="10" fill="currentColor" />
    </svg>
  </BaseIcon>
);

MoreTwoToneIcon.defaultProps = BaseIcon.defaultProps;

export { MoreTwoToneIcon };
