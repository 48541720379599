import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { BadgeProps, BadgeOrigin } from './Badge.types';

const mapAnchorOriginToClassName = (badgeOrigin: BadgeOrigin): string => {
  const verticalOrigin = capitalizeFirstLetter(badgeOrigin.vertical);
  const horizontalOrigin = capitalizeFirstLetter(badgeOrigin.horizontal);
  return `Badge-anchorOrigin${verticalOrigin}${horizontalOrigin}`;
};

const Badge: React.FC<BadgeProps> = ({
  className,
  style,
  badgeContent,
  anchorOrigin,
  children,
  format,
}) => {
  const anchorOriginClassName = mapAnchorOriginToClassName(anchorOrigin!);
  const formattedContent = format!(badgeContent);

  return (
    <span
      data-testid="Badge-root"
      className={clsx('Badge-root', className)}
      style={style}
    >
      {children}
      <span className={clsx('Badge-badge', anchorOriginClassName)}>
        {formattedContent}
      </span>
    </span>
  );
};

const StyledBadge = styled(Badge)`
  &.Badge-root {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex-shrink: 0;
  }

  .Badge-badge {
    position: absolute;
    z-index: 1;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}
  }

  .Badge-anchorOriginBottomLeft {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  .Badge-anchorOriginBottomCenter {
    bottom: 0;
    left: 0;
    margin-left: 50%;
    transform: translate(-50%, 100%);
  }

  .Badge-anchorOriginBottomRight {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }

  .Badge-anchorOriginMiddleLeft {
    left: 0;
    top: 0;
    margin-top: 50%;
    transform: translate(-100%, -50%);
  }

  .Badge-anchorOriginMiddleCenter {
    top: 0;
    left: 0;
    margin-left: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
  }

  .Badge-anchorOriginMiddleRight {
    top: 0;
    right: 0;
    margin-top: 50%;
    transform: translate(100%, -50%);
  }

  .Badge-anchorOriginTopLeft {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .Badge-anchorOriginTopCenter {
    top: 0;
    left: 0;
    margin-left: 50%;
    transform: translate(-50%, -100%);
  }

  .Badge-anchorOriginTopRight {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

StyledBadge.defaultProps = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  },
  format: (badgeContent: BadgeProps['badgeContent']): string => {
    if (badgeContent <= 999) {
      return `${badgeContent}`;
    }

    if (badgeContent <= 999999) {
      const cappedContent = Math.round(badgeContent / 1000);
      return `${cappedContent}k`;
    }

    if (badgeContent <= 999999999) {
      const cappedContent = Math.round(badgeContent / 1000000);
      return `${cappedContent}M`;
    }

    if (badgeContent > 999999999) {
      return '999M+';
    }

    return '0';
  },
};

export { StyledBadge as Badge };
