import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { BackIconProps } from './BackIcon.types';

const BackIcon: React.FC<BackIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="BackIcon-root"
    className={clsx('BackIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8236 10H1.17651M1.17651 10L8.23534 2.94116M1.17651 10L8.23534 17.0588"
        strokeWidth="1.17647"
        strokeLinecap="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

BackIcon.defaultProps = BaseIcon.defaultProps;

export { BackIcon };
