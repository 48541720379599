import React from 'react';
import styled from 'styled-components';

import { ListItemIconProps } from './ListItemIcon.types';

export const ListItemIconRoot = styled.div`
  min-width: 32px;
  color: var(--common-black);
  flex-shrink: 0;
  display: inline-flex;
`;

export const listItemIconClassNames = {
  root: ListItemIconRoot.toString().slice(1),
};

export const ListItemIcon = React.forwardRef<HTMLDivElement, ListItemIconProps>(
  function ListItemIcon({ className, style, children }, ref) {
    return (
      <ListItemIconRoot
        data-testid="ListItemIcon"
        ref={ref}
        className={className}
        style={style}
      >
        {children}
      </ListItemIconRoot>
    );
  },
);
