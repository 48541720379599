import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { OrganiseIconProps } from './OrganiseIcon.types';

const OrganiseIcon: React.FC<OrganiseIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="OrganiseIcon-root"
    className={clsx('OrganiseIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99979 7.75276V1.33301M9.99979 1.33301L7.43188 3.90091M9.99979 1.33301L12.5677 3.90091M9.99979 12.2466V18.6663M9.99979 18.6663L12.5677 16.0984M9.99979 18.6663L7.43188 16.0984"
        stroke="currentColor"
      />
      <path
        d="M12.2465 9.99954L18.6663 9.99954M18.6663 9.99954L16.0984 7.43164M18.6663 9.99954L16.0984 12.5674M7.75268 9.99954L1.33293 9.99954M1.33293 9.99954L3.90083 12.5674M1.33293 9.99954L3.90083 7.43164"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

OrganiseIcon.defaultProps = BaseIcon.defaultProps;

export { OrganiseIcon };
