import styled from 'styled-components';

import { expandedClassName } from '../Accordion';

export const AccordionDetails = styled.div.attrs({
  'data-testid': 'AccordionDetails',
})`
  display: none;
  padding-bottom: 16px;

  .${expandedClassName} & {
    display: block;
  }
`;
