import { css, FlattenSimpleInterpolation } from 'styled-components';
import * as polished from 'polished';

import { createError } from '../../utils/createError';
import { Theme, BreakpointAlias } from '..';
import { breakpoints } from './breakpoints';

export const batmanTheme: Theme = {
  name: 'batman',
  // Breakpoints
  breakpoints,
  // Colors
  colors: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    grey: {
      '1': '#303030',
      '2': '#707070',
      '3': '#888888',
      '4': '#CECFD1',
      '5': '#EFEFEF',
      '6': '#F5F5F5',
      '7': '#FBFBFB',
    },
    secondary: {
      main: '#FDCA47',
      light: '#FEDF91',
      lighter: '#FEEAB5',
    },
    background: {
      green: '#1D4B30',
      blue: '#140E3F',
      maroon: '#53103C',
    },
    green: {
      main: '#2A8539',
    },
    red: {
      main: '#AE1A26',
    },
    orange: {
      main: '#FF7F2A',
    },
  },

  // Typography
  typography: {
    primary: {
      'defaults': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontStyle: 'normal',
      },
      'b68': {
        fontWeight: 700,
        fontSize: '68px',
        lineHeight: '80px',
      },
      'b42': {
        fontWeight: 700,
        fontSize: '42px',
        lineHeight: '50px',
      },
      'b34': {
        fontWeight: 700,
        fontSize: '34px',
        lineHeight: '42px',
      },
      'b26': {
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '38px',
      },
      'b18': {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
      },
      'b16': {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '23px',
      },
      'b14': {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
      },
      'b12-allcaps': {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '18px',
        textTransform: 'uppercase',
      },
      'm16': {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
      },
      'm12': {
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
      },
      'r26': {
        fontWeight: 400,
        fontSize: '26px',
        lineHeight: '38px',
      },
      'r20': {
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '30px',
      },
      'r18': {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
      },
      'r16': {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
      },
      'r14': {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
      'r12': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
      },
      'r10': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '16px',
      },
      'l26': {
        fontWeight: 300,
        fontSize: '26px',
        lineHeight: '38px',
      },
      'l20': {
        fontWeight: 300,
        fontSize: '20px',
        lineHeight: '30px',
      },
      'l16': {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '24px',
      },
      'l14': {
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    branded: {
      defaults: {
        fontFamily: '"Josefin Sans", sans-serif',
      },
      b42: {
        fontWeight: 700,
        fontSize: '42px',
        lineHeight: '50px',
      },
      b18: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
      },
      b14: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
      },
      l26: {
        fontWeight: 300,
        fontSize: '26px',
        lineHeight: '38px',
      },
    },
    editorial: {
      defaults: {
        fontFamily: '"Juana", serif',
      },
      r46: {
        fontWeight: 400,
        fontSize: '46px',
        lineHeight: '60px',
      },
      r26: {
        fontWeight: 400,
        fontSize: '26px',
        lineHeight: '38px',
      },
      r22: {
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '32px',
      },
    },
  },

  // Box shadows
  shadows: [
    'none',
    'rgb(0 0 0 / 25%) 0px 1px 2px',
    '0px 3px 6px rgba(0, 0, 0, 0.25)',
  ],

  // Transition
  transition: '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  // Shapes are styles that shape the components (square, round, etc...)
  shapes: {
    main: {
      borderRadius: '2px',
    },
    cta: {
      borderRadius: '30px',
    },
    round: {
      borderRadius: '50%',
    },
  },

  // Reused borders
  borders: {
    'primary': {
      main: '1px solid #000000',
    },
    'secondary': {
      main: '1px solid #cecfd1',
    },
    'cta-primary': {
      main: '1px solid #000000',
      active: '1px solid #303030',
    },
    'cta-secondary': {
      main: '1px solid #ffffff',
      hover: '1px solid #000000',
    },
  },

  // Util functions
  get fns() {
    const getColor = (
      color?: string,
      alphaChannel?: number | string,
    ): string => {
      if (!color) {
        return '';
      }

      const [name, variant] = color.split('.');
      const colorObject = this.colors[name];

      if (colorObject) {
        const fromPath = colorObject[variant ?? 'main'];
        const actualColor = fromPath ?? color;

        return alphaChannel !== undefined
          ? this.fns.transparentize(alphaChannel, actualColor)
          : actualColor;
      }

      return alphaChannel !== undefined
        ? this.fns.transparentize(alphaChannel, name)
        : name;
    };

    const getBorder = (border: string): string => {
      const [name, variant] = border.split('.');
      const borderObject = this.borders[name];

      if (borderObject) {
        const fromPath = borderObject[variant ?? 'main'];

        if (fromPath) {
          return fromPath;
        }

        throw createError('ThemeError', 'Border not found');
      }

      throw createError('ThemeError', 'Border group not found');
    };

    const getTransition = (properties: Array<string>): string => {
      const transition = this.transition;
      return properties
        .map((property) => `${property} ${transition}`)
        .join(', ');
    };

    const getSpacing = (...spaces: Array<number>): string => {
      return spaces.map((s) => `${s}px`).join(' ');
    };

    const getTypographyStyles = (
      typography: string,
    ): FlattenSimpleInterpolation => {
      const [group, name] = typography.split('.');
      const groupObject = this.typography[group];

      if (!groupObject) {
        throw createError('ThemeError', 'Typography group not found');
      }

      const defaults = groupObject.defaults;
      const typographyObject = groupObject[name];

      if (!defaults && !typographyObject) {
        throw createError('ThemeError', 'Typography and defaults not found');
      }

      return css({
        ...defaults,
        ...typographyObject,
      });
    };

    const getShapeStyles = (shapeName: string): FlattenSimpleInterpolation => {
      const shapeObject = this.shapes[shapeName];

      if (shapeObject) {
        return css(shapeObject);
      }

      throw createError('ThemeError', 'Shape not found');
    };

    const getMediaQuery = ({
      minWidth,
      maxWidth,
    }: {
      minWidth?: BreakpointAlias | number;
      maxWidth?: BreakpointAlias | number;
    }): string => {
      const queries: Array<string> = [];

      if (minWidth) {
        const isAlias = typeof minWidth === 'string';
        const actualMinWidth = isAlias ? this.breakpoints[minWidth] : minWidth;
        queries.push(`(min-width: ${actualMinWidth}px)`);
      }

      if (maxWidth) {
        const isAlias = typeof maxWidth === 'string';
        const actualMaxWidth = isAlias ? this.breakpoints[maxWidth] : maxWidth;
        queries.push(`(max-width: ${actualMaxWidth}px)`);
      }

      return `@media ${queries.join(' and ')}`;
    };

    const highlight = (amount: number | string, color: string): string => {
      const lighterColor = this.fns.lighten(amount, color);
      const darkerColor = this.fns.darken(amount, color);

      return this.fns.readableColor(color, darkerColor, lighterColor, false);
    };

    return {
      ...polished,
      getColor,
      getBorder,
      getTransition,
      getSpacing,
      getTypographyStyles,
      getShapeStyles,
      getMediaQuery,
      highlight,
    };
  },
};
