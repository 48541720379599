import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CheckboxCheckedIconProps } from './CheckboxCheckedIcon.types';

const CheckboxCheckedIcon: React.FC<CheckboxCheckedIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="CheckboxCheckedIcon-root"
    className={clsx('CheckboxCheckedIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5385 2H4.46154C3.80892 2.00071 3.18323 2.26028 2.72176 2.72176C2.26028 3.18323 2.00071 3.80892 2 4.46154V15.5385C2.00071 16.1911 2.26028 16.8168 2.72176 17.2782C3.18323 17.7397 3.80892 17.9993 4.46154 18H15.5385C16.1911 17.9993 16.8168 17.7397 17.2782 17.2782C17.7397 16.8168 17.9993 16.1911 18 15.5385V4.46154C17.9993 3.80892 17.7397 3.18323 17.2782 2.72176C16.8168 2.26028 16.1911 2.00071 15.5385 2ZM14.1635 7.31885L8.99423 13.4727C8.93753 13.5402 8.86697 13.5948 8.78734 13.6327C8.70771 13.6706 8.62087 13.6909 8.53269 13.6923H8.52231C8.43605 13.6923 8.35077 13.6741 8.27199 13.639C8.1932 13.6039 8.12268 13.5526 8.065 13.4885L5.84962 11.0269C5.79335 10.9672 5.74959 10.8969 5.72089 10.8201C5.69219 10.7433 5.67914 10.6615 5.6825 10.5795C5.68587 10.4976 5.70558 10.4171 5.74047 10.3429C5.77537 10.2687 5.82476 10.2022 5.88572 10.1473C5.94668 10.0925 6.018 10.0504 6.09548 10.0234C6.17295 9.99654 6.25502 9.98539 6.33687 9.99065C6.41872 9.99592 6.49869 10.0175 6.57208 10.0541C6.64547 10.0907 6.7108 10.1416 6.76423 10.2038L8.50615 12.1392L13.2212 6.52731C13.3269 6.40503 13.4766 6.32928 13.6377 6.31644C13.7989 6.3036 13.9586 6.35469 14.0824 6.45868C14.2062 6.56266 14.2841 6.71119 14.2993 6.87215C14.3144 7.0331 14.2656 7.19356 14.1635 7.31885Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

CheckboxCheckedIcon.defaultProps = BaseIcon.defaultProps;

export { CheckboxCheckedIcon };
