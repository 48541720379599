import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { CtaButtonProps } from './CtaButton.types';

const sizeSmallClassName = 'CtaButton-sizeSmall';
const sizeMediumClassName = 'CtaButton-sizeMedium';
const sizeLargeClassName = 'CtaButton-sizeLarge';
const variantPrimaryBlackClassName = 'CtaButton-variantPrimaryBlack';
const variantPrimaryWhiteClassName = 'CtaButton-variantPrimaryWhite';
const variantSecondaryClassName = 'CtaButton-variantSecondary';

const CtaButtonRoot = styled(ButtonBase)`
  ${({ theme }) => theme.fns.getShapeStyles('cta')}

  &.${sizeSmallClassName} {
    height: 36px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b14')}

    &.${buttonBaseClassNames.withIcons}:not(.${buttonBaseClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
    }
  }

  &.${sizeMediumClassName} {
    height: 42px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b18')}

    &.${buttonBaseClassNames.withIcons}:not(.${buttonBaseClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r18')}
    }
  }

  &.${sizeLargeClassName} {
    height: 54px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b26')}

    &.${buttonBaseClassNames.withIcons}:not(.${buttonBaseClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r26')}
    }
  }

  &.${variantPrimaryBlackClassName}:not(:disabled),
  &.${variantPrimaryWhiteClassName}:not(:disabled) {
    border: ${({ theme }) => theme.fns.getBorder('cta-primary')};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${variantPrimaryBlackClassName}:not(:disabled):hover {
    background: ${({ theme }) => theme.fns.getColor('grey.1')};
  }

  &.${variantPrimaryBlackClassName}:not(:disabled):active {
    background: ${({ theme }) => theme.fns.getColor('common.black')};
    border: ${({ theme }) => theme.fns.getBorder('cta-primary.active')};
  }

  &.${variantSecondaryClassName}:not(:disabled) {
    border: ${({ theme }) => theme.fns.getBorder('cta-secondary')};
    color: ${({ theme }) => theme.fns.getColor('common.white')};
    background: transparent;

    &:hover {
      border: ${({ theme }) => theme.fns.getBorder('cta-secondary.hover')};
      color: ${({ theme }) => theme.fns.getColor('common.black')};
      background: ${({ theme }) => theme.fns.getColor('common.white')};
    }
  }
`;

export const ctaButtonClassNames = {
  sizeSmall: sizeSmallClassName,
  sizeMedium: sizeMediumClassName,
  sizeLarge: sizeLargeClassName,
  variantPrimaryBlack: variantPrimaryBlackClassName,
  variantPrimaryWhite: variantPrimaryWhiteClassName,
  variantSecondary: variantSecondaryClassName,
};

const mapSizeToClassName = {
  small: ctaButtonClassNames.sizeSmall,
  medium: ctaButtonClassNames.sizeMedium,
  large: ctaButtonClassNames.sizeLarge,
};

const mapVariantToClassName = {
  'primary-black': ctaButtonClassNames.variantPrimaryBlack,
  'primary-white': ctaButtonClassNames.variantPrimaryWhite,
  'secondary': ctaButtonClassNames.variantSecondary,
};

export const CtaButton = React.forwardRef<HTMLButtonElement, CtaButtonProps>(
  (
    {
      className,
      style,
      variant = 'primary-black',
      size = 'medium',
      fullWidth = false,
      disabled = false,
      loading = false,
      type = 'button',
      startIcon,
      endIcon,
      onClick,
      children,
    },
    ref,
  ) => (
    <CtaButtonRoot
      ref={ref}
      className={clsx(
        mapSizeToClassName[size],
        mapVariantToClassName[variant],
        className,
      )}
      style={style}
      color={variant === 'primary-black' ? 'common.black' : 'common.white'}
      fullWidth={fullWidth}
      disabled={disabled}
      loading={loading}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      onClick={onClick}
    >
      {children}
    </CtaButtonRoot>
  ),
);
