import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MoveDownIconProps } from './MoveDownIcon.types';

const MoveDownIcon: React.FC<MoveDownIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="MoveDownIcon-root"
    className={clsx('MoveDownIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 9.56884L10 19L17.5 9.56884H14V1H6V9.56884H2.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

MoveDownIcon.defaultProps = BaseIcon.defaultProps;

export { MoveDownIcon };
