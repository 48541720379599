import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ViewIconProps } from './ViewIcon.types';

const ViewIcon: React.FC<ViewIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="ViewIcon-root"
    className={clsx('ViewIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.136 9.27612C14.1365 17.5764 5.0139 16.2089 1.00001 9.27626C6.09555 2.09354 14.1349 2.39056 19.136 9.27612Z"
        stroke="currentColor"
      />
      <circle cx="9.93629" cy="9.49342" r="2.93629" stroke="currentColor" />
    </svg>
  </BaseIcon>
);

ViewIcon.defaultProps = BaseIcon.defaultProps;

export { ViewIcon };
