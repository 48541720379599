import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { SearchIconProps } from './SearchIcon.types';

const SearchIcon: React.FC<SearchIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="SearchIcon-root"
    className={clsx('SearchIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8397 16.4608L14.1643 12.7855C15.0492 11.6075 15.5269 10.1736 15.5252 8.7003C15.5252 4.93702 12.4635 1.87531 8.70024 1.87531C4.93696 1.87531 1.87524 4.93702 1.87524 8.7003C1.87524 12.4636 4.93696 15.5253 8.70024 15.5253C10.1736 15.5269 11.6074 15.0492 12.7854 14.1644L16.4608 17.8398C16.6468 18.0061 16.8895 18.0948 17.1389 18.0879C17.3884 18.0809 17.6257 17.9787 17.8022 17.8022C17.9786 17.6258 18.0808 17.3884 18.0878 17.139C18.0948 16.8896 18.006 16.6469 17.8397 16.4608ZM3.82524 8.7003C3.82524 7.73612 4.11116 6.79359 4.64683 5.9919C5.1825 5.19021 5.94387 4.56537 6.83466 4.19639C7.72545 3.82741 8.70565 3.73087 9.65131 3.91898C10.597 4.10708 11.4656 4.57138 12.1474 5.25316C12.8292 5.93494 13.2935 6.80358 13.4816 7.74924C13.6697 8.6949 13.5731 9.6751 13.2042 10.5659C12.8352 11.4567 12.2103 12.218 11.4086 12.7537C10.607 13.2894 9.66443 13.5753 8.70024 13.5753C7.40779 13.5738 6.16871 13.0596 5.25481 12.1457C4.34091 11.2318 3.82679 9.99276 3.82524 8.7003Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

SearchIcon.defaultProps = BaseIcon.defaultProps;

export { SearchIcon };
