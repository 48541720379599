import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CheckCircleOutlinedIconProps } from './CheckCircleOutlinedIcon.types';

const CheckCircleOutlinedIcon: React.FC<CheckCircleOutlinedIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="CheckCircleOutlinedIcon-root"
    className={clsx('CheckCircleOutlinedIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 10C17.5 5.85937 14.1406 2.5 10 2.5C5.85937 2.5 2.5 5.85937 2.5 10C2.5 14.1406 5.85937 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10Z"
        strokeWidth="1.25274"
        strokeMiterlimit="10"
        stroke="currentColor"
      />
      <path
        d="M13.75 6.875L8.5 13.125L6.25 10.625"
        strokeWidth="1.25274"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

CheckCircleOutlinedIcon.defaultProps = BaseIcon.defaultProps;

export { CheckCircleOutlinedIcon };
