import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { HangerAddIconProps } from './HangerAddIcon.types';

const HangerAddIcon: React.FC<HangerAddIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="HangerAddIcon-root"
    className={clsx('HangerAddIcon-root', className)}
    style={style}
    size={size}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4.40942V9.40942M14.5 6.90942H19.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62305 5.37715C7.3469 5.37715 7.12305 5.15329 7.12305 4.87715C7.12305 3.28814 8.41119 2 10.0002 2C10.0638 2 10.127 2.00207 10.1896 2.00613C11.6904 2.10361 12.8774 3.35176 12.8774 4.87716C12.8774 6.29563 11.851 7.47434 10.5003 7.71102V9.23527C10.5003 9.251 10.4996 9.26656 10.4981 9.28192L18.2367 13.9091C19.7404 14.7273 20.0826 17.1818 18.2636 18H1.81497C-0.104993 17.1818 0.23674 14.7273 1.81467 13.9091L9.50603 9.31123C9.50225 9.28646 9.50029 9.26109 9.50029 9.23527V7.25431C9.50029 6.97817 9.72415 6.75431 10.0003 6.75431C11.0341 6.75431 11.8727 5.91858 11.8774 4.88586L11.8773 4.87715C11.8773 3.89486 11.1229 3.0888 10.1618 3.00686C10.1402 3.00502 10.1186 3.00355 10.0968 3.00244C10.0648 3.00082 10.0327 3.00001 10.0003 3.00001L9.99804 3C8.96231 3.00117 8.12305 3.84115 8.12305 4.87715C8.12305 5.15329 7.89919 5.37715 7.62305 5.37715ZM2.27499 14.7968C2.29287 14.7876 2.31048 14.7778 2.32777 14.7674L10.0266 10.1651L17.7235 14.7674C17.7352 14.7743 17.7469 14.781 17.7588 14.7875C18.176 15.0145 18.4691 15.5021 18.4978 16.0108C18.5207 16.4178 18.3773 16.773 18.0181 17H2.04504C1.62649 16.7608 1.48159 16.3932 1.50175 16.0066C1.52702 15.5222 1.81888 15.0333 2.27499 14.7968Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

HangerAddIcon.defaultProps = BaseIcon.defaultProps;

export { HangerAddIcon };
