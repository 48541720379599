import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { GiftIconProps } from './GiftIcon.types';

const GiftIcon: React.FC<GiftIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="GiftIcon-root"
    className={clsx('GiftIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99976 3.42308V5.84615H12.4228C12.9021 5.84615 13.3705 5.70404 13.769 5.43779C14.1675 5.17154 14.4781 4.79311 14.6615 4.35035C14.8449 3.90759 14.8928 3.42039 14.7994 2.95036C14.7059 2.48033 14.4751 2.04858 14.1362 1.7097C13.7973 1.37083 13.3656 1.14005 12.8956 1.04656C12.4255 0.953065 11.9383 1.00105 11.4956 1.18445C11.0528 1.36784 10.6744 1.67842 10.4081 2.07689C10.1419 2.47536 9.99976 2.94384 9.99976 3.42308V3.42308Z"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke="currentColor"
      />
      <path
        d="M10.0002 3.42308C10.0002 4.09202 10.0002 5.84615 10.0002 5.84615H7.57713C7.09789 5.84615 6.62941 5.70404 6.23094 5.43779C5.83247 5.17154 5.5219 4.79311 5.3385 4.35035C5.1551 3.90759 5.10712 3.42039 5.20061 2.95036C5.29411 2.48033 5.52488 2.04858 5.86376 1.7097C6.20263 1.37083 6.63438 1.14005 7.10441 1.04656C7.57444 0.953065 8.06164 1.00105 8.5044 1.18445C8.94716 1.36784 9.32559 1.67842 9.59185 2.07689C9.8581 2.47536 10.0002 2.94384 10.0002 3.42308V3.42308Z"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke="currentColor"
      />
      <path
        d="M16.9229 5.84619H3.07675C2.31205 5.84619 1.69214 6.4661 1.69214 7.23081V9.30773C1.69214 10.0724 2.31205 10.6923 3.07675 10.6923H16.9229C17.6876 10.6923 18.3075 10.0724 18.3075 9.30773V7.23081C18.3075 6.4661 17.6876 5.84619 16.9229 5.84619Z"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M16.9231 10.6924V16.9232C16.9231 17.474 16.7042 18.0023 16.3147 18.3918C15.9252 18.7813 15.397 19.0001 14.8461 19.0001H5.15383C4.60299 19.0001 4.07472 18.7813 3.68522 18.3918C3.29572 18.0023 3.0769 17.474 3.0769 16.9232V10.6924"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M9.99976 5.84619V19"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

GiftIcon.defaultProps = BaseIcon.defaultProps;

export { GiftIcon };
