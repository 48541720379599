import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CommentOutlinedIconProps } from './CommentOutlinedIcon.types';

const CommentOutlinedIcon: React.FC<CommentOutlinedIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="CommentOutlinedIcon-root"
    className={clsx('CommentOutlinedIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 9.55557C18.003 10.7288 17.7289 11.8861 17.2 12.9333C16.5728 14.1882 15.6086 15.2437 14.4155 15.9816C13.2223 16.7195 11.8473 17.1106 10.4444 17.1111C9.27122 17.1142 8.11387 16.8401 7.06666 16.3111L2 18L3.68889 12.9333C3.15994 11.8861 2.88583 10.7288 2.88889 9.55557C2.88943 8.15269 3.28054 6.77766 4.01841 5.58451C4.75629 4.39135 5.81178 3.42719 7.06666 2.80002C8.11387 2.27107 9.27122 1.99697 10.4444 2.00003H10.8889C12.7416 2.10224 14.4916 2.88426 15.8037 4.19634C17.1157 5.50843 17.8978 7.25837 18 9.11112V9.55557Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

CommentOutlinedIcon.defaultProps = BaseIcon.defaultProps;

export { CommentOutlinedIcon };
