import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { MoreCircleIconProps } from './MoreCircleIcon.types';

const MoreCircleIcon: React.FC<MoreCircleIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="MoreCircleIcon-root"
    className={clsx('MoreCircleIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="currentColor"
      />
      <circle r="1" cx="5" cy="10" fill="currentColor" />
      <circle r="1" cx="10" cy="10" fill="currentColor" />
      <circle r="1" cx="15" cy="10" fill="currentColor" />
    </svg>
  </BaseIcon>
);

MoreCircleIcon.defaultProps = BaseIcon.defaultProps;

export { MoreCircleIcon };
