import React from 'react';
import clsx from 'clsx';
import { BaseIcon } from '../BaseIcon';

import { InfoIconProps } from './InfoIcon.types';

const InfoIcon: React.FC<InfoIconProps> = ({ className, style, size }) => (
  <BaseIcon
    date-testid="InfoIcon-root"
    className={clsx('InfoIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 7.26825C9.534 7.26825 9.15625 6.89049 9.15625 6.4245C9.15625 5.95851 9.534 5.58075 9.99999 5.58075C10.466 5.58075 10.8437 5.95851 10.8437 6.4245C10.8437 6.89049 10.466 7.26825 9.99999 7.26825Z"
        fill="white"
      />
      <rect
        x="9.15625"
        y="8.23218"
        width="1.68747"
        height="7.07143"
        rx="0.843737"
        fill="white"
      />
    </svg>
  </BaseIcon>
);

InfoIcon.defaultProps = BaseIcon.defaultProps;

export { InfoIcon };
