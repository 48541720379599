import React from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';

import { LoadingDots } from '../LoadingDots';
import { ButtonBaseRef, ButtonBaseProps } from './ButtonBase.types';

const loadingClassName = 'ButtonBase-loading';
const withIconsClassName = 'ButtonBase-withIcons';
const fullWidthClassName = 'Moonsift-fullWidth';

const ButtonBaseRoot = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  min-width: 100px;
  cursor: pointer;
  padding: 7px 26px;
  border: 0;
  outline: 0;

  ${({ theme, color: colorProp }) => {
    const color = theme.fns.getColor(colorProp!);
    const textColor = theme.fns.readableColor(color);
    const transition = theme.fns.getTransition([
      'background-color',
      'box-shadow',
      'border',
    ]);

    return css`
      background: ${color};
      color: ${textColor};
      transition: ${transition};
    `;
  }}

  &:disabled {
    &,
    &:hover,
    &:active {
      cursor: not-allowed;
      background: var(--grey-5);
      border: 1px solid var(--grey-5);
      color: var(--grey-2);
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${loadingClassName}:not(:disabled) {
    cursor: wait;
    box-shadow: ${({ theme }) => theme.shadows[0]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${withIconsClassName} {
    padding: 7px 12px;
  }
`;

const ButtonBaseStartIcon = styled.span`
  display: inherit;
  margin-right: 8px;

  > *:first-child {
    font-size: 20px;
  }
`;

const ButtonBaseEndIcon = styled.span`
  display: inherit;
  margin-left: 8px;

  > *:first-child {
    font-size: 20px;
  }
`;

const ButtonBaseLabel = styled.span`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  .${loadingClassName} & {
    opacity: 0;
  }
`;

const ButtonBaseLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const buttonBaseClassNames = {
  root: ButtonBaseRoot.toString().slice(1),
  withIcons: withIconsClassName,
  fullWidth: fullWidthClassName,
  loading: loadingClassName,
  loadingContainer: ButtonBaseLoadingContainer.toString().slice(1),
  startIcon: ButtonBaseStartIcon.toString().slice(1),
  endIcon: ButtonBaseEndIcon.toString().slice(1),
  label: ButtonBaseLabel.toString().slice(1),
};

export const ButtonBase = React.forwardRef<ButtonBaseRef, ButtonBaseProps>(
  (
    {
      className,
      style,
      color = 'common.black',
      disabled = false,
      fullWidth = false,
      loading = false,
      type = 'button',
      loadingIcon = <LoadingDots />,
      startIcon,
      endIcon,
      onClick,
      onMouseEnter,
      onMouseLeave,
      children,
    },
    ref,
  ) => {
    return (
      <ButtonBaseRoot
        ref={ref}
        type={type}
        data-testid="ButtonBase"
        className={clsx(
          className,
          fullWidth && buttonBaseClassNames.fullWidth,
          !disabled && loading && buttonBaseClassNames.loading,
          (startIcon || endIcon) && buttonBaseClassNames.withIcons,
        )}
        style={style}
        color={color}
        disabled={disabled}
        onClick={loading ? undefined : onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading && (
          <ButtonBaseLoadingContainer>{loadingIcon}</ButtonBaseLoadingContainer>
        )}
        <ButtonBaseLabel>
          <>
            {startIcon && (
              <ButtonBaseStartIcon>{startIcon}</ButtonBaseStartIcon>
            )}
            {children}
            {endIcon && <ButtonBaseEndIcon>{endIcon}</ButtonBaseEndIcon>}
          </>
        </ButtonBaseLabel>
      </ButtonBaseRoot>
    );
  },
);
