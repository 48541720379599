import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { useControlled } from '../../../hooks/useControlled';
import { CheckboxUncheckedIcon } from '../../icons/CheckboxUncheckedIcon';
import { CheckboxCheckedIcon } from '../../icons/CheckboxCheckedIcon';
import { CheckboxProps } from './Checkbox.types';

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  className,
  style,
  defaultChecked,
  checked: checkedProp,
  disabled,
  required,
  onChange,
}) => {
  const [checked, setChecked] = useControlled<boolean>({
    controlled: checkedProp,
    default: Boolean(defaultChecked),
  });

  const handleChange = React.useCallback<NonNullable<typeof onChange>>(
    (event) => {
      // Workaround to avoid onChange handler executions when
      // event.preventDefault has been called inside the same element's onClick
      // handler.
      // https://github.com/facebook/react/issues/9023
      if (event.nativeEvent.defaultPrevented) {
        return;
      }

      setChecked(event.target.checked);

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, setChecked],
  );

  return (
    <span
      data-testid="Checkbox-root"
      className={clsx(
        'Checkbox-root',
        disabled && 'Checkbox-disabled',
        checked ? 'Checkbox-checked' : 'Checkbox-unchecked',
        className,
      )}
      style={style}
    >
      <input
        id={id}
        name={name}
        className="Checkbox-input"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        required={required}
        onChange={handleChange}
      />
      {checked ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
    </span>
  );
};

const StyledCheckbox = styled(Checkbox)`
  &.Checkbox-root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;

    padding: ${({ theme }) => theme.fns.getSpacing(6)};
    color: var(--common-black);

    ${({ theme }) => theme.fns.getShapeStyles('round')}

    &:not(.Checkbox-disabled):hover {
      background-color: ${({ theme }) =>
        theme.fns.getColor('common.black', 0.96)};
    }

    &.Checkbox-disabled {
      cursor: not-allowed;

      > .BaseIcon-root {
        color: var(--grey-4);
      }
    }
  }

  &.Checkbox-unchecked {
    color: var(--grey-2);
  }

  &.Checkbox-checked {
    color: var(--common-black);
  }

  .Checkbox-input {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;
  }
`;

StyledCheckbox.displayName = 'Checkbox';

StyledCheckbox.defaultProps = {
  disabled: false,
  required: false,
};

export { StyledCheckbox as Checkbox };
