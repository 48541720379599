import React from 'react';
import styled from 'styled-components';

import { Typography } from '../Typography';
import { ListItemTextProps } from './ListItemText.types';

export const ListItemTextRoot = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

export const listItemTextClassNames = {
  root: ListItemTextRoot.toString().slice(1),
};

export const ListItemText = React.forwardRef<HTMLDivElement, ListItemTextProps>(
  function ListItemText({ className, style, children }, ref) {
    return (
      <ListItemTextRoot
        data-testid="ListItemText"
        ref={ref}
        className={className}
        style={style}
      >
        <Typography variant="primary.r14">{children}</Typography>
      </ListItemTextRoot>
    );
  },
);
