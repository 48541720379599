import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { LoadingMoreIconProps } from './LoadingMoreIcon.types';

const LoadingMoreIcon: React.FC<LoadingMoreIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="LoadingMoreIcon-root"
    className={clsx('LoadingMoreIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="1" cx="5" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;7;10;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="10" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;7;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="15" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;10;7;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </BaseIcon>
);

LoadingMoreIcon.defaultProps = BaseIcon.defaultProps;

export { LoadingMoreIcon };
