import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import { FormHelperTextProps } from './FormHelperText.types';

const FormHelperText: React.FC<FormHelperTextProps> = ({
  className,
  style,
  children,
  error,
}) => (
  <p
    data-testid="FormHelperText-root"
    className={clsx(
      'FormHelperText-root',
      error && 'Moonsift-error',
      className,
    )}
    style={style}
  >
    {children}
  </p>
);

const StyledFormHelperText = styled(FormHelperText)`
  &.FormHelperText-root {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}
  }
`;

StyledFormHelperText.displayName = 'FormHelperText';

export { StyledFormHelperText as FormHelperText };
