import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ExtensionIconProps } from './ExtensionIcon.types';

const ExtensionIcon: React.FC<ExtensionIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="ExtensionIcon-root"
    className={clsx('ExtensionIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0625 12.375H21.375V7.875C21.375 6.6375 20.3625 5.625 19.125 5.625H14.625V3.9375C14.625 2.385 13.365 1.125 11.8125 1.125C10.26 1.125 9 2.385 9 3.9375V5.625H4.5C3.2625 5.625 2.26125 6.6375 2.26125 7.875V12.15H3.9375C5.61375 12.15 6.975 13.5112 6.975 15.1875C6.975 16.8638 5.61375 18.225 3.9375 18.225H2.25V22.5C2.25 23.7375 3.2625 24.75 4.5 24.75H8.775V23.0625C8.775 21.3862 10.1363 20.025 11.8125 20.025C13.4888 20.025 14.85 21.3862 14.85 23.0625V24.75H19.125C20.3625 24.75 21.375 23.7375 21.375 22.5V18H23.0625C24.615 18 25.875 16.74 25.875 15.1875C25.875 13.635 24.615 12.375 23.0625 12.375Z"
        fill="currentColor"
      />
    </svg>
  </BaseIcon>
);

ExtensionIcon.defaultProps = BaseIcon.defaultProps;

export { ExtensionIcon };
