import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { BookmarkIconProps } from './BookmarkIcon.types';

const BookmarkIcon: React.FC<BookmarkIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="BookmarkIcon-root"
    className={clsx('BookmarkIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 18V2H17V18L10 10.967L3 18Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

BookmarkIcon.defaultProps = BaseIcon.defaultProps;

export { BookmarkIcon };
