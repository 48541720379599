import React from 'react';
import styled, { css } from 'styled-components';

import { StackProps } from './Stack.types';

export const StackRoot = styled.div<StackProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  & > :not(style) + :not(style) {
    ${({ theme, direction, spacing }) => {
      if (!spacing) return '';

      const margin = [
        direction === 'column' ? spacing : 0,
        direction === 'row-reverse' ? spacing : 0,
        direction === 'column-reverse' ? spacing : 0,
        direction === 'row' ? spacing : 0,
      ];

      return css`
        margin: ${theme.fns.getSpacing(...margin)};
      `;
    }}
  }
`;

export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  (
    {
      className,
      style,
      direction = 'column',
      wrap = 'nowrap',
      alignItems = 'stretch',
      justifyContent = 'flex-start',
      spacing = 0,
      onClick,
      children,
    },
    ref,
  ) => {
    return (
      <StackRoot
        data-testid="Stack"
        ref={ref}
        className={className}
        style={style}
        direction={direction}
        wrap={wrap}
        alignItems={alignItems}
        justifyContent={justifyContent}
        spacing={spacing}
        onClick={onClick}
      >
        {children}
      </StackRoot>
    );
  },
);
