import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { CloseIconProps } from './CloseIcon.types';

const CloseIcon: React.FC<CloseIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="CloseIcon-root"
    className={clsx('CloseIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.75 17.75L2 2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 2L2 17.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </BaseIcon>
);

CloseIcon.defaultProps = BaseIcon.defaultProps;

export { CloseIcon };
