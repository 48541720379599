import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { LaunchIconProps } from './LaunchIcon.types';

const LaunchIcon: React.FC<LaunchIconProps> = ({ className, style, size }) => (
  <BaseIcon
    data-testid="LaunchIcon-root"
    className={clsx('LaunchIcon-root', className)}
    style={style}
    size={size}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 2.1875H12.5M17.5 2.1875V7.1875M17.5 2.1875L8.125 11.5625M8.75 4.0625H5C3.61929 4.0625 2.5 5.18179 2.5 6.5625V15.3125C2.5 16.6932 3.61929 17.8125 5 17.8125H13.75C15.1307 17.8125 16.25 16.6932 16.25 15.3125V10.9375"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

LaunchIcon.defaultProps = BaseIcon.defaultProps;

export { LaunchIcon };
