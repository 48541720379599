import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ArrowDownwardIconProps } from './ArrowDownwardIcon.types';

const ArrowDownwardIcon: React.FC<ArrowDownwardIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="ArrowDownwardIcon-root"
    className={clsx('ArrowDownwardIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.88891 2.00006L9.88891 18M9.88891 18L17 11.1765M9.88891 18L2.77783 11.1765"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </BaseIcon>
);

ArrowDownwardIcon.defaultProps = BaseIcon.defaultProps;

export { ArrowDownwardIcon };
