import React from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

import { ButtonBase } from '../ButtonBase';
import { LoadingMoreCircleIcon } from '../../icons/LoadingMoreCircleIcon';
import { FabRef, FabProps } from './Fab.types';

const variantStandardClassName = 'Fab-variantStandard';
const variantShadowClassName = 'Fab-variantShadow';

const FabRoot = styled(ButtonBase)<FabProps>`
  margin: 0;
  padding: 0;
  border: 0;
  min-width: auto;

  ${({ theme }) => theme.fns.getShapeStyles('round')}

  &.${variantStandardClassName}:not(:disabled) {
    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }
  }

  &.${variantShadowClassName}:not(:disabled) {
    &:hover {
      background: var(--grey-6);
    }

    &:active {
      background: var(--grey-5);
    }
  }

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`;

export const fabClassNames = {
  root: FabRoot.toString().slice(1),
  loading: 'Fab-loading',
};

const mapVariantToClassName = {
  standard: variantStandardClassName,
  shadow: variantShadowClassName,
};

export const Fab = React.forwardRef<FabRef, FabProps>(
  (
    {
      className,
      style,
      disabled = false,
      loading = false,
      variant = 'standard',
      size = 36,
      type = 'button',
      loadingIcon = <LoadingMoreCircleIcon />,
      hoverIcon,
      onClick,
      children,
    },
    ref,
  ) => {
    const [hovering, setHovering] = React.useState(false);
    const handleMouseEnter = React.useCallback(() => setHovering(true), []);
    const handleMouseLeave = React.useCallback(() => setHovering(false), []);

    return (
      <FabRoot
        ref={ref}
        className={clsx(
          mapVariantToClassName[variant],
          loading && loadingIcon && fabClassNames.loading,
          className,
        )}
        style={style}
        loading={loading}
        loadingIcon={loadingIcon}
        fullWidth={false}
        color="common.white"
        disabled={disabled}
        type={type}
        size={size}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {hovering && hoverIcon ? hoverIcon : children}
      </FabRoot>
    );
  },
);
