import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { BaseIconProps } from './BaseIcon.types';

export const mapSizeToClassName: Record<
  NonNullable<BaseIconProps['size']>,
  string
> = {
  small: 'BaseIcon-small',
  medium: 'BaseIcon-medium',
  large: 'BaseIcon-large',
  larger: 'BaseIcon-larger',
};

const BaseIcon: React.FC<BaseIconProps> = ({
  className,
  style,
  size,
  children,
  ...otherProps
}) => {
  if (React.isValidElement(children)) {
    const { className: childClassName, style: childStyle } = children.props;

    return React.cloneElement(children, {
      'aria-hidden': true,
      'focusable': false,
      'className': clsx(
        'BaseIcon-root',
        mapSizeToClassName[size!],
        className,
        childClassName,
      ),
      'style': { ...childStyle, ...style },
      size,
      ...otherProps,
    });
  }

  return null;
};

const StyledBaseIcon = styled(BaseIcon).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return !['secondaryColor'].includes(prop) && defaultValidatorFn(prop);
  },
})`
  &.BaseIcon-root {
    width: 1em;
    height: 1em;

    &.${mapSizeToClassName.small} {
      font-size: 16px;
    }

    &.${mapSizeToClassName.medium} {
      font-size: 20px;
    }

    &.${mapSizeToClassName.large} {
      font-size: 24px;
    }

    &.${mapSizeToClassName.larger} {
      font-size: 28px;
    }
  }

  color: ${({ theme, color }) => theme.fns.getColor(color)};
  --secondary-color: ${({ theme, secondaryColor }) =>
    theme.fns.getColor(secondaryColor)};
`;

StyledBaseIcon.displayName = 'BaseIcon';

StyledBaseIcon.defaultProps = {
  size: 'medium',
};

export { StyledBaseIcon as BaseIcon };
