import React from 'react';
import clsx from 'clsx';

import { BaseIcon } from '../BaseIcon';
import { ChevronUpIconProps } from './ChevronUpIcon.types';

const ChevronUpIcon: React.FC<ChevronUpIconProps> = ({
  className,
  style,
  size,
}) => (
  <BaseIcon
    data-testid="ChevronUpIcon-root"
    className={clsx('ChevronUpIcon-root', className)}
    style={style}
    size={size}
  >
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.34331 18.6475C5.79949 19.1175 6.53498 19.1175 6.99116 18.6475L14.7277 10.6763C15.0908 10.3022 15.0908 9.69784 14.7277 9.32374L6.99116 1.35252C6.53498 0.882493 5.7995 0.882493 5.34331 1.35252C4.88713 1.82254 4.88713 2.58033 5.34331 3.05036L12.0837 10.0048L5.334 16.9592C4.88713 17.4197 4.88712 18.187 5.34331 18.6475Z"
        fill="currentColor"
        transform="rotate(270 10 10)"
      />
    </svg>
  </BaseIcon>
);

ChevronUpIcon.defaultProps = BaseIcon.defaultProps;

export { ChevronUpIcon };
